import { Button, Form, Row, Col, Input, Typography } from 'antd';
import RedoIcon from '@/components/Icons/RedoIcon';
import { FC } from 'react';
import useVerify from '@/hooks/useVerify'; // Import the custom hook
import { useTranslation } from 'react-i18next';
import { LOCAL_EN } from '@/config';
import { convertArabicToEnglishNumbers } from '@/utils';

interface VerifyFormProps {
  title: string;
  description: string;
  phoneNumber: string;
  resendText: string;
  timeUnit: string;
  whatsappText: string;
  submitText: string;
  isLoading?: boolean;
  onResendClick?: () => void;
  countdown?: number;
}

const VerifyFormCard: FC<VerifyFormProps> = ({
  title,
  description,
  phoneNumber,
  resendText,
  timeUnit,
  whatsappText,
  submitText,
  isLoading,
  onResendClick,
  countdown,
}) => {
  const { otp, errors, handleOtpChange, handleSubmit, onFinishFailed, inputRefs, handleBackspace } =
    useVerify();
  const { i18n } = useTranslation();
  const { Text } = Typography;

  const handleKeyUp = (index: number, key: string) => {
    if (key === 'Backspace') {
      handleBackspace(index);
    } else {
      // Convert Arabic numbers to English numbers if they are entered in Arabic
      const pressedNumber = Number(key) || Number(convertArabicToEnglishNumbers(key));
      // Check if pressedNumber is a valid number
      if (!Number.isNaN(pressedNumber)) {
        handleOtpChange(index, String(pressedNumber));
      }
    }
  };

  return (
    <Row>
      <Col span={24} className='rounded-[20px]  overflow-hidden shadow-deep-shadow bg-white'>
        <Form
          name='verify'
          initialValues={{ remember: true }}
          onFinishFailed={onFinishFailed}
          className='h-full w-[514px] flex flex-col pt-[99px] justify-center items-center mx-auto'
        >
          <Col className='h-full flex flex-col items-center  justify-center'>
            <Col className='w-[270px]  '>
              <Col className='mb-6 '>
                <Text className='text-[24px]  uppercase  text-customTunaPrimary whitespace-pre-line font-[900]  text-center leading-[27.8px]'>
                  {title}
                </Text>
              </Col>
              <Text className='font-[400] w-full block text-[14px]  leading-[22px] tracking-[-0.32px]  text-customTunaPrimary text-center'>
                {description}
              </Text>
              <Text
                style={{ direction: 'ltr' }}
                className='font-[900] block  text-[24px] leading-[27.8px] text-customTunaPrimary my-6  text-center'
              >
                {phoneNumber}
              </Text>

              {/* OTP Input Section */}
              <Form.Item>
                <Col style={{ direction: 'ltr' }} className='flex gap-x-4 justify-center'>
                  {otp.map((value, index) => (
                    <Input
                      key={index}
                      maxLength={1}
                      value={value}
                      ref={(el) => (inputRefs.current[index] = el)} // Assigning ref to the InputRef object
                      onKeyUp={(e) => handleKeyUp(index, e.key)}
                      className={`text-center font-[400]  text-[16px] leading-[20.48px]  w-[56px] h-[56px] rounded-[8px] border-solid border ${
                        errors[index] ? 'border-customRed' : 'border-customGrayShade5'
                      }`}
                    />
                  ))}
                </Col>
              </Form.Item>
              <Col
                // style={{ direction: direction === 'rtl' ? 'rtl' : 'ltr' }}
                className='flex justify-center space-x-2 items-center -mt-[3px] '
              >
                <Col className='flex items-center pl-1 '>
                  {' '}
                  <RedoIcon
                    width={24}
                    height={24}
                    onClick={onResendClick}
                    className='mr-2 cursor-pointer'
                    color={countdown === 0 ? 'transparent' : 'gray'}
                  />
                  <Text
                    className={`font-[700] text-[14px]  leading-[16.8px] cursor-pointer ${countdown === 0 ? 'text-customBlue' : 'gray'}`}
                    onClick={onResendClick}
                  >
                    {resendText}
                  </Text>
                </Col>
                <Text className='font-[400] text-[14px]  leading-[16.8px] text-customGrayShade5'>
                  {timeUnit}
                </Text>
              </Col>
            </Col>
            <Form.Item>
              <Col
                // style={{ direction: direction === 'rtl' ? 'rtl' : 'ltr' }}
                className={`flex ${i18n.language === LOCAL_EN ? '' : 'space-x-reverse'} space-x-6 mt-[108px] justify-between items-center pb-[75px]`}
              >
                {/* <Button
                  htmlType='button'
                  className='text-customTunaPrimary w-[172px] h-[56px] rounded-[8px] -mt-6 flex justify-center items-center text-[14px] font-[700] leading-[13.54px] border-[0.81px] border-customTunaPrimary'
                >
                  {whatsappText}
                </Button> */}
                <Button
                  type='primary'
                  htmlType='button'
                  onClick={() => handleSubmit()}
                  className='text-customTunaPrimary w-[172px] h-[56px] rounded-[8px] -mt-6 flex justify-center items-center text-[14px] font-[700] leading-[13.54px] bg-customYellowPrimary'
                  loading={isLoading}
                >
                  {submitText}
                </Button>
              </Col>
            </Form.Item>
          </Col>
        </Form>
      </Col>
    </Row>
  );
};

export default VerifyFormCard;
