import { ErrorData } from '@/types';
import { message } from 'antd';
import i18next from 'i18next';

export const handleErrorMessage = (
  error: ErrorData,
  defaultErrorMessage?: string,
  isMutation?: boolean,
) => {
  if (error.statusCode === 422 && error.object) {
    for (const key in error.object) {
      if (error.object?.[key] && Array.isArray(error.object[key])) {
        message.error(error.object[key]);
      }
    }
  } else {
    const generalErrorMessage =
      error.message ||
      defaultErrorMessage ||
      i18next.t(isMutation ? 'operation_failed' : 'unexpected_error');

    message.error(generalErrorMessage);
  }
};
