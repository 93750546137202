import { create } from 'zustand';

// interface ProgressToastData {
//   fileName: string;
//   toastText: string;
// }
// interface ProgressBar {
//   progressToastData?: ProgressToastData;
//   setProgressToastData: (value?: ProgressToastData) => void;
// }

// const useProgressBarStore = create<ProgressBar>((set) => ({
//   progressToastData: undefined,
//   setProgressToastData: (value) => set({ progressToastData: value }),
// }));

// export default useProgressBarStore;

export interface ProgressToastData {
  id: string;
  fileName?: string;
  toastText: string;
  hideDelay?: number;
  onHideCallback?: () => void;
}

interface ProgressBarStore {
  toasts: ProgressToastData[];
  addToast: (toast: Omit<ProgressToastData, 'id'>) => void;
  removeToast: (id: string) => void;
}

const useProgressBarStore = create<ProgressBarStore>((set) => ({
  toasts: [],
  addToast: (toast) => {
    const id = Math.random().toString(36).substring(2, 9);
    set((state) => ({ toasts: [...state.toasts, { id, ...toast }] }));

    if (toast.hideDelay !== undefined) {
      setTimeout(() => {
        set((state) => ({ toasts: state.toasts.filter((t) => t.id !== id) }));
        toast.onHideCallback?.();
      }, toast.hideDelay);
    }
  },
  removeToast: (id) => set((state) => ({ toasts: state.toasts.filter((t) => t.id !== id) })),
}));

export default useProgressBarStore;
