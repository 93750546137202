import { axiosJSONInstance } from '@/config';
import {
  AccessTokenDataListResponse,
  AccessTokenResponse,
  ResponseType,
  WebHooksCreateRequest,
  WebHooksDataListResponse,
  WebHooksDataResponse,
  WebHooksEventDataListResponse,
  WebHooksProperty,
  WebHooksPropertyCreateRequest,
  WebHooksPropertyListResponse,
} from '@/types';

export const fetchApiTokens = async (): Promise<ResponseType<AccessTokenDataListResponse>> => {
  const response = await axiosJSONInstance.get('/merchants/api-tokens');

  return response.data;
};

export const createApiToken = async (name: string): Promise<ResponseType<AccessTokenResponse>> => {
  const response = await axiosJSONInstance.post('/merchants/api-tokens', { name });

  return response.data;
};

export const deleteApiToken = async (apiKey: string): Promise<void> => {
  const response = await axiosJSONInstance.delete(`/merchants/api-tokens/${apiKey}`);

  return response.data;
};

export const fetchWebhooks = async (): Promise<ResponseType<WebHooksDataListResponse>> => {
  const response = await axiosJSONInstance.get('/merchants/webhooks');

  return response.data;
};

export const fetchWebhookByUid = async (
  uid: string,
): Promise<ResponseType<WebHooksDataResponse>> => {
  const response = await axiosJSONInstance.get(`/merchants/webhooks/${uid}`);

  return response.data;
};

export const deleteWebhooks = async (uid: string): Promise<void> => {
  const response = await axiosJSONInstance.delete(`/merchants/webhooks/${uid}`);

  return response.data;
};

export const createWebhooks = async (
  data: WebHooksCreateRequest,
): Promise<ResponseType<WebHooksDataResponse>> => {
  const response = await axiosJSONInstance.post('/merchants/webhooks', data);

  return response.data;
};

export const updateWebhooks = async (
  webhookUid: string,
  data: WebHooksCreateRequest,
): Promise<void> => {
  const response = await axiosJSONInstance.patch(`/merchants/webhooks/${webhookUid}`, data);

  return response.data;
};

export const fetchWebhooksEvents = async (): Promise<
  ResponseType<WebHooksEventDataListResponse>
> => {
  const response = await axiosJSONInstance.get('/merchants/events');

  return response.data;
};

export const fetchWebhooksProperties = async (
  webhookUid: string,
): Promise<ResponseType<WebHooksPropertyListResponse>> => {
  const response = await axiosJSONInstance.get(`/merchants/webhooks/${webhookUid}/properties`);

  return response.data;
};

export const createWebhookProperty = async (
  data: WebHooksPropertyCreateRequest,
): Promise<ResponseType<WebHooksProperty>> => {
  const { webhookUid, ...propertyData } = data;
  const response = await axiosJSONInstance.post(
    `/merchants/webhooks/${webhookUid}/properties`,
    propertyData,
  );

  return response.data;
};

export const deleteWebhookProperty = async (webhookUid: string, uid: string): Promise<void> => {
  const response = await axiosJSONInstance.delete(
    `/merchants/webhooks/${webhookUid}/properties/${uid}`,
  );

  return response.data;
};

export const updateWebhookProperty = async (
  webhookUid: string,
  data: WebHooksProperty,
): Promise<void> => {
  const response = await axiosJSONInstance.patch(
    `/merchants/webhooks/${webhookUid}/properties/${data.uid}`,
    {
      ...data,
    },
  );

  return response.data;
};
