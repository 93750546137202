import { Flex, Input, notification, Select } from 'antd';
import { AddIcon, ImportIcon, SearchIcon, ExportIcon2 } from '../../Icons';
import { useTranslation } from 'react-i18next';
import { ROUTE, routes } from '@/config';
import useSearchStore from '@/hooks/useSearchStore';
import debounce from 'lodash.debounce';
import { useState } from 'react';
import { ExportModal } from './ExportModal/ExportModal';
import dayjs from 'dayjs';
import { exportOrders } from '@/utils';
import OrderHeaderButton from './OrderHeaderButton';
import { useExportFilesAsync } from '@/hooksApi';
import { useNavigate } from 'react-router-dom';
import { SettingSection } from '@/pages/settings';
import { useQueryClient } from '@tanstack/react-query';
import { useProgressBarStore } from '@/stores';

const { Option } = Select;

const OrderPageHeader = () => {
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { addToast } = useProgressBarStore();

  const searchItems = [
    { label: 'phone_number', value: 'filter_phone' },
    { label: 'address', value: 'filter_address_text' },
    { label: 'first_name', value: 'filter_full_name' },
    { label: 'package_size', value: 'filter_size' },
    { label: 'platform_code', value: 'filter_platform_code' },
    { label: 'pickup_address', value: 'filter_pick_up_address_title' },
    { label: 'filter_custom_id', value: 'filter_custom_ids[]' },
  ];
  // store search in
  const { searchValue, setSearchValue } = useSearchStore();

  const { mutate: exportFilesAsync } = useExportFilesAsync();

  // Handle select change
  const handleFilterChange = (value: string) => {
    setSearchValue({ ...searchValue, searchBy: value });
  };

  // Handle search input change
  const handleSearchInputChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue({ ...searchValue, search: e.target.value });
  }, 500);

  const handleExport = async (
    selectedDateRange?: string[],
    selectedMonthRange?: string[],
    selectedStatuses?: string[],
  ) => {
    const dates = selectedDateRange ?? selectedMonthRange;

    const queryParts = [
      `filter_created_at_from=${dates ? dates[0] : dayjs().subtract(1, 'month').format('YYYY-MM-DD')}`,
      `filter_created_at_to=${dates ? dates[1] : dayjs().format('YYYY-MM-DD')}`,
    ];
    if (selectedStatuses) {
      selectedStatuses.forEach((status) => {
        queryParts.push(`filter_statuses[]=${status}`);
      });
    }

    exportFilesAsync(queryParts.join('&'), {
      onSuccess: () => {
        addToast({
          fileName: 'fileName',
          toastText: t('export_is_in_queue'),
          hideDelay: 8000,
          onHideCallback: () => queryClient.invalidateQueries(['exported-file']),
        });
        navigate(ROUTE.SETTINGS, { state: { activeTab: SettingSection.File } });
      },
    });
  };

  return (
    <Flex gap='middle' justify='space-between' className='mt-2 sm:mb-2'>
      <Flex gap='16px'>
        <Select
          placeholder={t('search_by')}
          className='w-1/2 h-full min-w-[140px]'
          allowClear
          size='middle'
          value={searchValue.searchBy}
          onChange={handleFilterChange}
        >
          {searchItems?.map((search, index) => (
            <Option key={index} value={search?.value}>
              {t(search?.label)}
            </Option>
          ))}
        </Select>
        <Input
          size='middle'
          placeholder={t('search')}
          prefix={<SearchIcon />}
          className='w-full rounded-lg'
          allowClear
          onChange={handleSearchInputChange}
          defaultValue={searchValue?.search || ''}
        />
      </Flex>
      <OrderHeaderButton label={t('import')} icon={ImportIcon} navigateLink={ROUTE.IMPORT} />
      <OrderHeaderButton
        label={t('export')}
        icon={ExportIcon2}
        onClick={() => setIsExportModalOpen(true)}
      />
      <OrderHeaderButton
        label={t('new_order')}
        icon={AddIcon}
        isPrimary
        navigateLink={ROUTE.ADDNEWORDER}
      />

      <ExportModal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        onExport={handleExport}
      />
    </Flex>
  );
};

export default OrderPageHeader;
