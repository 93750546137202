import {
  Card,
  CardAvailableCod,
  Heading,
  NetIcon,
  PickupsIcon,
  RemittanceTable,
  Review,
  RightIcon,
  Shipping,
  TotalCodInitIcon,
  TotalCodReciveIcon,
  TotalCodRemittedIcon,
  TotalRevenueIcon,
} from '@/components';
import { ROUTE } from '@/config';
import { FinanceTab } from '@/types';
import { Col, Flex, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

interface BalanceProps {
  setActiveTab: (tab: FinanceTab) => void;
}

const Balance = ({ setActiveTab }: BalanceProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Flex vertical>
      {/* Start AVAILABLE COD And Shipping */}
      {/*V0 remove*/}
      {/* <Row gutter={[24, 16]}>
        <Col lg={12} md={24} xs={24}>
          <CardAvailableCod showChart={false} />
        </Col>
        Start Shipping
        <Col lg={12} md={24} xs={24}>
          <Shipping />
        </Col>
        End Shipping
      </Row> */}
      {/*End AVAILABLE COD And Shipping */}

      {/* Start Review And REMITTANCE */}
      <Row gutter={16}>
        {/*V0 remove*/}
        <Col xxl={12} xl={10} lg={24} md={24} xs={24} className='mt-6'>
          <CardAvailableCod showChart={false} />
        </Col>
        {/*V0 remove*/}
        {/* <Col md={12} xs={24} lg={12}>
          <Flex align='center' justify='space-between' className='mt-4'>
            <Heading title={t('cod_review')} fontSize='text-lg' fontWeight='font-bold' />
            <Select variant='borderless' defaultValue={t('today')} className='font-bold'>
              <Option value={t('today')}>{t('today')}</Option>
              <Option value={t('today')}>{t('today')}</Option>
              <Option value={t('today')}>{t('today')}</Option>
            </Select>
          </Flex>
          <Row gutter={[24, 16]}>
            <Col lg={8} md={24} xs={24}>
              <Review
                title={t('total_cod')}
                icon={<TotalCodRemittedIcon />}
                textTooltip={t('total_cod')}
                value={20000000}
              />
            </Col>
            <Col lg={8} md={24} xs={24}>
              <Review
                title={t('total_init')}
                icon={<TotalCodInitIcon />}
                textTooltip={t('total_init')}
                value={45000000}
              />
            </Col>
            <Col lg={8} md={24} xs={24}>
              <Review
                title={t('total_received')}
                icon={<TotalCodReciveIcon />}
                textTooltip={t('total_received')}
                value={50000000}
              />
            </Col>
          </Row>
          <Flex vertical>
            <Heading title={t('sales_review')} fontSize='text-lg' fontWeight='font-bold' />
            <Row gutter={[24, 16]} className='mt-1'>
              <Col lg={8} md={24} xs={24}>
                <Review
                  title={t('total_revenue')}
                  icon={<TotalRevenueIcon />}
                  textTooltip={t('total_revenue')}
                  value={50000000}
                  status='+3.4%'
                />
              </Col>
              <Col lg={8} md={24} xs={24}>
                <Review
                  title={t('net_revenue')}
                  icon={<NetIcon />}
                  textTooltip={t('net_revenue')}
                  value={50000000}
                  status='+3.4%'
                />
              </Col>
              <Col lg={8} md={24} xs={24}>
                <Review
                  title={t('total_shipping')}
                  icon={<PickupsIcon />}
                  textTooltip={t('total_shipping')}
                  value={50000000}
                  status='-2.1%'
                />
              </Col>
            </Row>
          </Flex>
        </Col> */}
        <Col xxl={12} xl={14} lg={24} md={12} xs={24} className='mt-6'>
          <Card extraClassName='min-h-fit'>
            <Row>
              <Col md={19}>
                <Heading
                  title={t('cod_remittance')}
                  level={4}
                  extraClassName='mb-0'
                  fontWeight={'font-bold'}
                />
              </Col>
              <Col md={5}>
                <Heading
                  title={t('view_all')}
                  trailingIcon={<RightIcon className='mt-1' width={13} height={13} />}
                  parentClassName='justify-end cursor-pointer'
                  onClick={() => setActiveTab(FinanceTab.COD)}
                />
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col md={24}>
                <RemittanceTable />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {/* End Review And REMITTANCE */}
    </Flex>
  );
};

export default Balance;
