import { Button, Flex, Form, FormInstance, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddCoordinates, { CordsProps } from './AddCoordinates';
import AddCustomerDetails from './AddCustomerDetails';
import AddLocationInfo from './AddLocationInfo';
import { AddIcon } from '../Icons';
import { Heading } from '../Heading';
import usePickupAddressForm from '@/hooks/usePickupAddressForm';
import { usePickupAddressStore } from '@/stores';

interface ModalProps {
  visible: boolean;
  onClose: () => void;
  updatePreviewOrderPrice?: (pickupAddressUID?: string) => void;
}

const AddNewLocationModal: React.FC<ModalProps> = ({
  visible,
  onClose,
  updatePreviewOrderPrice,
}) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { pickupAddresses } = usePickupAddressStore();

  const [coordinates, setCoordinates] = useState<CordsProps | null>(null);

  const handleCoordinatesChange = (coords: CordsProps) => {
    setCoordinates(coords);
  };

  useEffect(() => {
    if (!pickupAddresses) {
      setCoordinates(null);
    }
  }, [pickupAddresses]);

  const { form, onFinish, loadingCreatePickup, loadingUpdatePickup, loadingSinglePickupAddress } =
    usePickupAddressForm({
      coordinates,
      setCoordinates,
      visible,
      onClose,
    });

  const handleSubmit = async (values: any) => {
    setSubmitted(true);
    const pickupAddressUID = await onFinish?.(values);

    updatePreviewOrderPrice?.(pickupAddressUID ?? undefined);
  };

  return (
    <Modal
      className='rounded-[20px] bg-white border border-customGrayShade3'
      title={
        <Flex
          className='font-bold text-lg leading-5  uppercase text-customTunaPrimary '
          style={{ borderBottom: '1px solid #E1E1E3', paddingBottom: '20px' }}
        >
          {pickupAddresses === null ? t('add_new_location') : t('edit_location')}
        </Flex>
      }
      width={709}
      centered
      open={visible}
      footer={null}
      onClose={onClose}
      onCancel={onClose}
    >
      {loadingSinglePickupAddress ? (
        <Spin />
      ) : (
        <Form form={form} onFinish={handleSubmit} initialValues={{ type: 'WAREHOUSE' }}>
          <AddCustomerDetails
            title={t('contact_info')}
            firstNamePlaceholder={t('name')}
            primaryPhonePlaceholder={t('primary_phone')}
          />
          <AddLocationInfo
            title={t('location_info')}
            locationNamePlaceholder={t('location_name')}
            regionPlaceholder={t('province')}
            cityPlaceholder={t('area')}
            addressPlaceholder={t('address')}
            typePlaceholder={t('type')}
            submitted={submitted}
            form={form}
          />
          <AddCoordinates
            title={t('location_coordinates')}
            searchPlaceholder={t('search_google_maps')}
            onCoordinatesChange={handleCoordinatesChange}
            coordinates={coordinates}
          />
          <Flex className='flex justify-end w-full gap-x-2'>
            <Button type='default' onClick={onClose}>
              <Heading title={t('cancel')} fontWeight='font-bold' />
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              loading={loadingCreatePickup || loadingUpdatePickup}
            >
              <Heading
                leadingIcon={<AddIcon width={18} height={18} />}
                title={pickupAddresses === null ? t('add_location') : t('edit_location')}
                fontWeight='font-bold'
              />
            </Button>
          </Flex>
        </Form>
      )}
    </Modal>
  );
};

export default AddNewLocationModal;
