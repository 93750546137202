import React, { useState } from 'react';
import { Steps, Button, Layout, Row, Flex, Col, Select, message } from 'antd';
import {
  BottomSheet,
  CustomRadio,
  DownloadIcon,
  Heading,
  RightArrowIcon,
  StepIcon,
  StepTickIcon,
} from '@/components';
import { ROUTE } from '@/config';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import { useTranslation } from 'react-i18next';
import { useMoveOrderExcelById } from '@/hooksApi/useImport';
import { PickupTypeEnum, UploadExcelResponse } from '@/types';
import { useCodDropOff } from '@/hooks/useCodDropOff ';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useCustomerIO, useFileUpload, useImportTableUid } from '@/hooks';
import { useFetchUser } from '@/hooksApi';
import { useConfirmModalStore, useProgressBarStore } from '@/stores';

const { Step } = Steps;

const Import: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<string>(t('boxy_collects'));
  const [pickupAddressId, setPickupAddressId] = useState<string | undefined>(undefined);
  const [id, setId] = useState<string | null>(null);
  const [row, setRow] = useState<number>(0);

  const navigation = useNavigate();
  const queryClient = useQueryClient();
  const { addToast } = useProgressBarStore();

  const { mutate: moveOrder, isLoading: isMoveOrderLoading } = useMoveOrderExcelById();
  const { pickupAddresses, pickupAddressLoading } = useCodDropOff();

  const setImportTableUid = useImportTableUid((state) => state.setImportTableUid); // Access the Table UID setter from Zustand

  const { handleBeforeUpload, uploadFile, uploadedFile, resetFile } =
    useFileUpload(onSuccessUpload);

  const { data: userData } = useFetchUser();
  const { openConfirm } = useConfirmModalStore();
  const { trackEvent } = useCustomerIO();

  const listCodDropOff = pickupAddresses?.filter((item) => item.type === PickupTypeEnum.WAREHOUSE);

  const steps = [
    {
      title: t('step1'),
      content: (
        <Step1
          handleBeforeUpload={handleBeforeUpload}
          uploadFile={uploadFile}
          uploadedFile={uploadedFile}
          handleResetUploadedFile={handleResetUploadedFile}
        />
      ),
      description: t('import'),
      icon: currentStep >= 1 ? <StepTickIcon /> : <StepIcon />,
      active: currentStep !== 0 ? true : false,
    },
    {
      title: t('step2'),
      content: <Step2 id={id} />,
      description: t('preview'),
      icon: currentStep >= 2 ? <StepTickIcon /> : <StepIcon />,
      active: currentStep >= 1 ? true : false,
    },
    {
      title: t('step3'),
      content: <Step3 id={id} setRow={setRow} />,
      description: t('submit'),
      icon: currentStep >= 3 ? <StepTickIcon /> : <StepIcon />,
      active: currentStep >= 2 ? true : false,
    },
  ];

  const next = () => {
    if (id === null && currentStep === 0) {
      message.error(t('must_upload_file'));
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  function onSuccessUpload(response: UploadExcelResponse) {
    setId(response?.object?.uid || '');

    setImportTableUid(response?.object?.uid || '');

    message.success(t('upload_Success'));

    setCurrentStep((prev: number) => prev + 1);
  }

  function handleResetUploadedFile() {
    setId(null);
    resetFile();
  }

  const handleSelect = (value: string) => {
    setSelectedValue(value);
  };

  const handleChange = (value: string) => {
    setPickupAddressId(value);
  };

  const handleSubmit = () => {
    if (selectedValue === t('boxy_collects') && pickupAddressId === '') {
      message.error(t('pickup_empty'));
      return;
    }
    if (userData?.object.verified_at) {
      moveOrder(
        {
          uid: id as string,
          data: {
            pick_up_type: selectedValue === t('boxy_collects') ? 'PICK_UP' : 'DROP_OFF',
            saved_pick_up_address_uid: pickupAddressId,
          },
        },
        {
          onSuccess: () => {
            message.success(t('operation'));
            navigation(ROUTE.ORDERS);
            addToast({
              fileName: uploadedFile?.name ?? '',
              toastText: t('import_is_in_queue'),
              hideDelay: 50000,
              onHideCallback: () => {
                queryClient.invalidateQueries(['orders']);
              },
            });
          },
        },
      );
    } else {
      openNotVerifiedConfirm();
    }
  };

  const openNotVerifiedConfirm = () => {
    openConfirm({
      description: t('user_not_verified'),
      onConfirm: () => {
        trackEvent(
          'request_verification',
          {
            userId: userData?.object?.uid,
            traits: {
              phone: userData?.object?.phone,
              user_type: 'MERCHANT',
              email: userData?.object?.email,
              firstname: userData?.object?.first_name,
              lastname: userData?.object?.last_name,
              fullname: `${userData?.object?.first_name}${userData?.object?.last_name}`,
            },
          },
          t('thank_you_for_choosing_Boxy_we_will_contact_you_soon'),
        );
      },
      approveText: t('request_activation'),
      cancelText: t('cancel'),
    });
  };

  return (
    <>
      <Layout className='overflow-visible relative w-full'>
        <Heading
          title={t('bulk_import')}
          routeLink={ROUTE.ORDERS}
          fontSize='text-2xl'
          fontWeight='font-bold'
        />

        <Steps current={currentStep} className='mt-12 px-6'>
          {steps.map((item) => (
            <Step
              key={item.title}
              title={item.title}
              description={item.description}
              icon={item.icon}
              className={`import-steps ${item.active ? 'active' : ''}`}
            />
          ))}
        </Steps>
        <Layout className='mt-8'>{steps[currentStep].content}</Layout>
      </Layout>
      <Row className='sticky bottom-6'>
        <div className='relative block w-full overflow-hidden h-[110px]'>
          <BottomSheet
            isVisible={currentStep !== 0}
            justify={currentStep === steps.length - 1 ? 'flex-start' : 'flex-end'}
          >
            <Row className='flex w-full justify-end'>
              <>
                {/* {currentStep !== steps.length - 1 && currentStep !== 0 && (
                  <Button onClick={prev} className='min-w-[8rem] mr-2'>
                    <Heading
                      title={t('previous')}
                      fontSize='text-sm'
                      fontWeight='font-bold'
                      color='text-customTunaPrimary'
                    />
                  </Button>
                )} */}
                {currentStep !== 0 && currentStep < steps.length - 1 && (
                  <Button
                    style={{ paddingBottom: '27px', paddingTop: '27px' }}
                    type='primary'
                    onClick={next}
                    className='min-w-[8rem]'
                  >
                    <Heading
                      title={t('next')}
                      fontSize='text-sm'
                      fontWeight='font-bold'
                      color='text-customTunaPrimary'
                      trailingIcon={<RightArrowIcon />}
                    />
                  </Button>
                )}
                {currentStep === steps.length - 1 && (
                  <Flex align='center' justify='space-between' className='w-full'>
                    <Flex>
                      <Flex vertical className='ml-4'>
                        <Heading title={t('pickup_drop')} fontWeight='font-bold' />
                        {/* <Col lg={12} md={24} xs={24}> */}
                        <Row gutter={[24, 16]} className='mt-2'>
                          <CustomRadio
                            title={t('boxy_collects')}
                            selected={selectedValue === t('boxy_collects')}
                            onSelect={() => handleSelect(t('boxy_collects'))}
                          />
                          {/* </Col> */}
                          {/*V0 remove */}
                          {/* <Col lg={12} md={24} xs={24}>
                            <CustomRadio
                              title={t('you_drop_off')}
                              selected={selectedValue === t('you_drop_off')}
                              onSelect={() => handleSelect(t('you_drop_off'))}
                            />
                          </Col> */}
                        </Row>
                      </Flex>
                      {selectedValue === t('boxy_collects') && (
                        <Flex vertical className='mx-4'>
                          <Heading title={t('pickup_location')} fontWeight='font-bold' />
                          <Row gutter={[24, 16]} className='mt-2'>
                            <Col lg={12} md={24} xs={24}>
                              <Select
                                style={{ width: 220, height: 50 }}
                                loading={pickupAddressLoading}
                                placeholder={t('pickup_location')}
                                onChange={handleChange}
                                options={listCodDropOff?.map((item) => ({
                                  label: item.title,
                                  value: item.uid,
                                }))}
                              />
                            </Col>
                          </Row>
                        </Flex>
                      )}
                    </Flex>
                    <Button
                      type='primary'
                      className='min-w-[8rem]'
                      loading={isMoveOrderLoading}
                      onClick={handleSubmit}
                      disabled={row > 0}
                    >
                      <Heading
                        title={t('import_orders')}
                        fontSize='text-sm'
                        fontWeight='font-bold'
                        color='text-customTunaPrimary'
                        leadingIcon={<DownloadIcon />}
                      />
                    </Button>
                  </Flex>
                )}
              </>
            </Row>
          </BottomSheet>
        </div>
      </Row>
    </>
  );
};

export default Import;
