import React from 'react';
import { Col, Flex, Form, Input, Row } from 'antd';
import { Heading } from '../Heading';
import { usePickupAddressStore } from '@/stores';
import { PhoneNumberInput } from '../PhoneNumberInput';

interface AddCustomerDetailsProps {
  title: string;
  firstNamePlaceholder: string;
  primaryPhonePlaceholder: string;
}

const AddCustomerDetails: React.FC<AddCustomerDetailsProps> = ({
  title,
  firstNamePlaceholder,
  primaryPhonePlaceholder,
}) => {
  const { pickupAddresses } = usePickupAddressStore();

  return (
    <Flex vertical className='mt-3'>
      <Heading
        title={title}
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='uppercase leading-[20.48px]'
      />
      <Row gutter={[24, 16]} className='mt-3'>
        <Col lg={12} md={24} xs={24}>
          <Form.Item name='full_name' rules={[{ required: true, message: '' }]}>
            <Input
              variant='outlined'
              placeholder={`${firstNamePlaceholder}*`}
              className='custom-input border custom-select-placeholder border-solid border-customGrayShade4 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg w-full'
            />
          </Form.Item>
        </Col>

        <Col lg={12} md={24} xs={24} className='flex gap-x-2' style={{ direction: 'ltr' }}>
          <PhoneNumberInput
            countryCodeName={'countryCode'}
            phoneNumberName={'phone'}
            phoneNumberPlaceholder={primaryPhonePlaceholder}
            // showCountryCode={!pickupAddresses} //! as requested in BO-443
            required
          />
        </Col>
      </Row>
    </Flex>
  );
};

export default AddCustomerDetails;
