import { DEFAULT_COUNTRY_CODE, LOCAL_EN } from '@/config';
import { checkPhoneNumberValidation, cleanNumber, cleanPhoneNumber } from '@/utils';
import { countries } from '@/utils/CountryCodes';
import { Select, Input, Image, Form, InputNumber } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  countryCodeName: string;
  phoneNumberName: string;
  phoneNumberPlaceholder: string;
  required?: boolean;
  onChange?: (value: string) => void;
  showCountryCode?: boolean;
}
export const PhoneNumberInput = ({
  required = true,
  countryCodeName,
  phoneNumberName,
  phoneNumberPlaceholder,
  onChange,
  showCountryCode = true,
}: Props) => {
  const [countryCode, setCountryCode] = useState(DEFAULT_COUNTRY_CODE);

  const { t, i18n } = useTranslation();

  const validatePhoneNumber = (_: any, value: string) => {
    if (value)
      if (!checkPhoneNumberValidation(value, countryCode)) {
        return Promise.reject(new Error('Invalid phone number'));
      }
    return Promise.resolve();
  };

  return (
    <>
      {showCountryCode && (
        <Form.Item
          name={countryCodeName}
          initialValue={countryCode}
          rules={[{ required, message: '' }]}
        >
          <Select
            variant='borderless'
            style={{ direction: 'ltr' }}
            className='border border-solid custom-select-item custom-select-placeholder border-customGrayShade5 h-[56px] rounded-[10px]'
            dropdownStyle={{ borderColor: 'red', borderWidth: '2px' }}
            showSearch
            onSelect={setCountryCode}
          >
            {countries.map((country, index) => (
              <Select.Option key={index} value={country.dialCode}>
                <span role='img'>
                  <Image preview={false} src={country.image} width={20} height={20} />
                </span>{' '}
                (+{country.dialCode})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        className='w-full'
        name={phoneNumberName}
        rules={[
          { required, message: t('phone_number_is_required') },
          { validator: validatePhoneNumber },
        ]}
        validateTrigger='onBlur'
      >
        <InputNumber
          controls={false}
          formatter={(value) => value} // Format to display clean and english number
          parser={cleanNumber}
          suffix={
            required ? (
              <span
                className={`absolute text-customRed text-xs font-bold ${i18n.language === LOCAL_EN ? 'right-3' : 'left-3'}`}
              >
                *
              </span>
            ) : undefined
          }
          autoComplete='off'
          // style={{ direction: 'ltr' }}
          placeholder={phoneNumberPlaceholder}
          onChange={(e) => onChange?.(e.target.value)}
          className='custom-input-number custom-input-number-without-controller border w-full border-solid border-customGrayShade5 custom-form-placeholder-input relative  placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
        />
      </Form.Item>
    </>
  );
};
